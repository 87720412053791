<template>
  <div id="app">
      <div id="wargames">
        <hello-world-mr-falken></hello-world-mr-falken>
      </div>
  </div>
</template>

<script>

    import HelloWorldMrFalken from './components/HelloWorldMrFalken.vue'

    export default {
      name: 'app',
      components: {
          HelloWorldMrFalken
      }
    }
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=VT323');

  html {
    height:100% !important;
  }

  body{
    background:#000;
    height:100% !important;
    margin:0px;
  }

  #app{
    height:100% !important;
  }

  #wargames{
    height:100% !important;
    position:relative;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAD0ElEQVR4Ae3WsQ2AMBDFUCAZAFGw/6KREDvE3bsBXFiW7p/38x6Ogd0Grt1APAZ+A8LSQWJAWIlW0DnGYIGB7QbmWms7FJABr1ADiQFhJVpBhaWBxIDxnmgFNd41kBjwChOtoMLSQGJAWIlWUONdA4kB4z3RCuoVaiAxIKxEK6iwNJAYMN4TraDGuwYSA15hohVUWBpIDAgr0QpqvGsgMWC8J1pBvUINJAaElWgFFZYGEgPGe6IV1HjXQGLAK0y0ggpLA4kBYSVaQY13DSQGjPdEK6hXqIHEgLASraDC0kBiwHhPtIIa7xpIDHiFiVZQYWkgMSCsRCuo8a6BxIDxnmgF9Qo1kBgQVqIVVFgaSAwY74lWUONdA4kBrzDRCiosDSQGhJVoBTXeNZAYMN4TraBeoQYSA8JKtIIKSwOJAeM90QpqvGsgMeAVJlpBhaWBxICwEq2gxrsGEgPGe6IV1CvUQGJAWIlWUGFpIDFgvCdaQY13DSQGvMJEK6iwNJAYEFaiFdR410BiwHhPtIJ6hRpIDAgr0QoqLA0kBoz3RCuo8a6BxIBXmGgFFZYGEgPCSrSCGu8aSAwY74lWUK9QA4kBYSVaQYWlgcSA8Z5oBTXeNZAY8AoTraDC0kBiQFiJVlDjXQOJAeM90QrqFWogMSCsRCuosDSQGDDeE62gxrsGEgNeYaIVVFgaSAwIK9EKarxrIDFgvCdaQb1CDSQGhJVoBRWWBhIDxnuiFdR410BiwCtMtIIKSwOJAWElWkGNdw0kBoz3RCuoV6iBxICwEq2gwtJAYsB4T7SCGu8aSAx4hYlWUGFpIDEgrEQrqPGugcSA8Z5oBfUKNZAYEFaiFVRYGkgMGO+JVlDjXQOJAa8w0QoqLA0kBoSVaAU13jWQGDDeE62gXqEGEgPCSrSCCksDiQHjPdEKarxrIDHgFSZaQYWlgcSAsBKtoMa7BhIDxnuiFdQr1EBiQFiJVlBhaSAxYLwnWkGNdw0kBrzCRCuosDSQGBBWohXUeNdAYsB4T7SCeoUaSAwIK9EKKiwNJAaM90QrqPGugcSAV5hoBRWWBhIDwkq0ghrvGkgMGO+JVlCvUAOJAWElWkGFpYHEgPGeaAU13jWQGPAKE62gwtJAYkBYiVZQ410DiQHjPdEK6hVqIDEgrEQrqLA0kBgw3hOtoMa7BhIDXmGiFVRYGkgMCCvRCmq8ayAxYLwnWkG9Qg0kBoSVaAUVlgYSA8Z7ohXUeNdAYsArTLSCCksDiQFhJVpBjXcNJAaM90QrqFeogcSAsBKtoMLSQGLAeE+0gn6NXXdvK2/RcgAAAABJRU5ErkJggg==); /*Noise*/
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    border-radius:20px;
    padding:10px 25px 20px;
   /* -webkit-box-shadow:0px 0px 0 rgb(194,197,198),0px 1px 0 rgb(191,194,195),0px 2px 0 rgb(189,192,193),0px 3px 0 rgb(186,189,190),0px 4px 0 rgb(183,186,187),0px 5px 0 rgb(180,183,184),0px 6px 0 rgb(178,181,182),0px 7px 0 rgb(175,178,179),0px 8px 0 rgb(172,175,176),0px 9px 0 rgb(169,172,173),0px 10px 0 rgb(167,170,171),0px 11px 0 rgb(164,167,168),0px 12px 0 rgb(161,164,165),0px 13px 0 rgb(158,161,162),0px 14px 0 rgb(156,159,160),0px 15px 0 rgb(153,156,157),0px 16px 0 rgb(150,153,154), 0px 17px 0 rgb(147,150,151),0px 18px 17px rgba(0,0,0,0.15),0px 18px 1px rgba(0,0,0,0.5),0px 0px 17px rgba(0,0,0,.2);
    -moz-box-shadow:0px 0px 0 rgb(194,197,198),0px 1px 0 rgb(191,194,195),0px 2px 0 rgb(189,192,193),0px 3px 0 rgb(186,189,190),0px 4px 0 rgb(183,186,187),0px 5px 0 rgb(180,183,184),0px 6px 0 rgb(178,181,182),0px 7px 0 rgb(175,178,179),0px 8px 0 rgb(172,175,176),0px 9px 0 rgb(169,172,173),0px 10px 0 rgb(167,170,171),0px 11px 0 rgb(164,167,168),0px 12px 0 rgb(161,164,165),0px 13px 0 rgb(158,161,162),0px 14px 0 rgb(156,159,160),0px 15px 0 rgb(153,156,157),0px 16px 0 rgb(150,153,154), 0px 17px 0 rgb(147,150,151),0px 18px 17px rgba(0,0,0,0.15),0px 18px 1px rgba(0,0,0,0.5),0px 0px 17px rgba(0,0,0,.2);
    box-shadow:0px 0px 0 rgb(194,197,198),0px 1px 0 rgb(191,194,195),0px 2px 0 rgb(189,192,193),0px 3px 0 rgb(186,189,190),0px 4px 0 rgb(183,186,187),0px 5px 0 rgb(180,183,184),0px 6px 0 rgb(178,181,182),0px 7px 0 rgb(175,178,179),0px 8px 0 rgb(172,175,176),0px 9px 0 rgb(169,172,173),0px 10px 0 rgb(167,170,171),0px 11px 0 rgb(164,167,168),0px 12px 0 rgb(161,164,165),0px 13px 0 rgb(158,161,162),0px 14px 0 rgb(156,159,160),0px 15px 0 rgb(153,156,157),0px 16px 0 rgb(150,153,154), 0px 17px 0 rgb(147,150,151),0px 18px 17px rgba(0,0,0,0.15),0px 18px 1px rgba(0,0,0,0.5),0px 0px 17px rgba(0,0,0,.2);*/
    text-align:left;
    padding: 10px 25px 0px;
  }


  #wargames:after{
    content:"";
    width:100%;
    position:absolute;
    bottom:-15px;
    left:0;
    /*-webkit-box-shadow:0px 20px 10px -10px #000;
    -moz-box-shadow:0px 20px 10px -10px #000;
    box-shadow:0px 20px 10px -10px #000;*/
  }

  #wargames h2{
    color:#edf2f3;
    text-align:center;
    padding-bottom:20px;
    text-shadow:0px 0px 0 rgb(48,48,45),0px 1px 0 rgb(21,21,18),0px 2px 0 rgb(-6,-6,-9),0px 3px 0 rgb(-33,-33,-36), 0px 6px 0 rgb(-114,-114,-117),0px 7px 6px rgba(0,0,0,0.85);
    font:600 2.9em Arial, Helvetica, Geneva, sans-serif;
    text-transform:uppercase;
    letter-spacing:0px;
  }

  #wargames pre{
    height:80%;
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    border-radius:50px;
    -webkit-filter:blur(0.3px);
    border-left:20px solid #1b1b1b;
    border-right:20px solid #1c1c1c;
    border-top:25px solid #0f1310;
    border-bottom:25px solid #0f1310;
    overflow:hidden;
    position:relative;
    color: #28c40c;
    padding:1.5em 1.5em 1.5em 2.5em;
    -webkit-box-shadow:inset 0 0 0 9px rgba(21,21,20,0.6),  0 1px 60px 10px rgba(40,46,40,9), inset 0 1px 80px 5px rgba(0,0,0,0.97);
    -moz-box-shadow:inset 0 0 0 9px rgba(21,21,20,0.6),  0 1px 60px 10px rgba(40,46,40,9), inset 0 1px 80px 5px rgba(0,0,0,0.97);
    box-shadow:inset 0 0 0 9px rgba(21,21,20,0.6),  0 1px 60px 10px rgba(40,46,40,9), inset 0 1px 80px 5px rgba(0,0,0,0.97);
    background-color:#162030;
    text-shadow: #8cfd7f 0 -1px 35px;
  }

  #wargames pre:before{
    content:"";
    width:100%;
    height:20%;
    position:absolute;
    top:0;
    left:0;
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(40,66,25,0.2)), color-stop(70%,rgba(32,57,17,0)), color-stop(99%,rgba(28,53,13,0)));
    -webkit-animation:scan 6.5s 1.0s infinite ease-in-out  forwards;
    -moz-animation:scan 6.5s 1.0s infinite ease-in-out  forwards;
    -ms-animation:scan 6.5s 1.0s infinite ease-in-out  forwards;
    -o-animation:scan 6.5s 1.0s infinite ease-in-out  forwards;
    animation:scan 6.5s 1.0s infinite ease-in-out  forwards;
  }


  #wargames pre:after{
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    opacity:1;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg==); /*scanline*/
    -webkit-filter:drop-shadow(15.32px 2.18px 7.35px rgb(27, 189, 8));
  }

  #wargames code{
    background:#fff;
    border:1px solid #DDD;
    padding:0.1em 0.2em;
  }

  #wargames pre code{
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
    background-color:transparent;
    border-style:none;
    font:400 22px VT323, monospace;
  }

  #wargames pre code span:last-child:after{
    content:"\220E";
    -webkit-animation:cursor .95s ease-in-out infinite;
    -moz-animation:cursor .95s ease-in-out infinite;
    -ms-animation:cursor .95s ease-in-out infinite;
    -o-animation:cursor .95s ease-in-out infinite;
    animation:cursor .95s ease-in-out infinite;
    font-size:100%;
  }

  /*Animations*/

  /* Cant' get "Scan" to work in Firefox*/
  @-webkit-keyframes scan{
    from { top: -50%;
      left: 0;
    }

    to {
      top: 150%;
      left: 0;
    }
  }


  @keyframes scan{
    from { top: -50%;
      left: 0;
    }

    to {
      top: 150%;
      left: 0;
    }
  }


  @-webkit-keyframes cursor{
    from { opacity: 1.0;
    }

    to {
      opacity: 0.0;
    }
  }


  @-moz-keyframes cursor{
    from { opacity: 1.0;
    }

    to {
      opacity: 0.0;
    }
  }


  @-ms-keyframes cursor{
    from { opacity: 1.0;
    }

    to {
      opacity: 0.0;
    }
  }


  @-o-keyframes cursor{
    from { opacity: 1.0;
    }

    to {
      opacity: 0.0;
    }
  }


  @keyframes cursor{
    from { opacity: 1.0;
    }

    to {
      opacity: 0.0;
    }
  }

  #footer{
        color: #4b4b4b;
        position: fixed;
        bottom: 10px;
        left: 10px;
        font:400 14px VT323, monospace;
    }

  #footer a{
      color: #4b4b4b;
      text-decoration: none;
  }


</style>
